import axios from 'axios'
import { useAuth } from '@/composables/useAuth'
import router from '@/router'

const { getToken, logout } = useAuth()

// Request interceptor
axios.interceptors.request.use(config => {
  const token = getToken()
  if (token) {
    config.headers.Authorization = token
  }
  
  config.headers['Access-Control-Allow-Origin'] = '*';

  return config
}, error => {
  return Promise.reject(error)
})

// Response interceptor
axios.interceptors.response.use(
  response => response,
  error => {
    if (error.response?.status === 401) {
      logout()
      router.push('/login')
    }
    return Promise.reject(error)
  }
)

export default axios 