<template>
  <div class="home-container">
    <TurkeyMap />
  </div>
</template>

<script>
import TurkeyMap from '@/components/TurkeyMap.vue'

export default {
  name: 'HomeView',
  components: {
    TurkeyMap
  }
}
</script>

<style scoped>
.home-container {
  padding: 20px;
  min-height: 100vh;
  box-sizing: border-box;
  background-color: #3544AA;
}
</style>