<template>
  <div class="login-container">
    <div class="login-box">
      <img src="@/assets/all.png" alt="Logo" class="login-logo">
      
      <form @submit.prevent="handleLogin" class="login-form">
        <div class="form-group">
          <label for="username">Kullanıcı Adı</label>
          <input 
            type="text" 
            id="username"
            v-model="username"
            required
            autocomplete="username"
          >
        </div>
        
        <div class="form-group">
          <label for="password">Şifre</label>
          <input 
            type="password" 
            id="password"
            v-model="password"
            required
            autocomplete="current-password"
          >
        </div>
        
        <div v-if="error" class="error-message">
          {{ error }}
        </div>
        
        <button 
          type="submit" 
          class="login-button"
          :disabled="loading"
        >
          {{ loading ? 'Giriş yapılıyor...' : 'Giriş Yap' }}
        </button>
      </form>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import { useRouter } from 'vue-router'
import axios from 'axios'
import { useAuth } from '@/composables/useAuth'

export default {
  name: 'LoginView',
  setup() {
    const router = useRouter()
    const { login } = useAuth()
    
    const username = ref('')
    const password = ref('')
    const loading = ref(false)
    const error = ref(null)
    
    const handleLogin = async () => {
      loading.value = true
      error.value = null
      
      try {
        const API_BASE_URL = process.env.VUE_APP_API_URL || 'http://127.0.0.1:8000'
        const response = await axios.post(`${API_BASE_URL}/login`, {
          username: username.value,
          password: password.value
        })
        
        await login(response.data.access_token, {
          username: response.data.username
        })
        
        router.push('/')
      } catch (err) {
        error.value = err.response?.data?.message || 'Giriş yapılırken bir hata oluştu'
        console.error('Login error:', err)
      } finally {
        loading.value = false
      }
    }
    
    return {
      username,
      password,
      loading,
      error,
      handleLogin
    }
  }
}
</script>

<style scoped>
.login-container {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #253077;
}

.login-box {
  background: #3544AA;
  padding: 40px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0,0,0,0.1);
  width: 100%;
  max-width: 400px;
}

.login-logo {
  display: block;
  margin: 0 auto 30px;
  height: 60px;
  width: auto;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: wheat;
}

.form-group input {
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 16px;
}

.login-button {
  background: #1976D2;
  color: white;
  border: none;
  padding: 12px;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.login-button:hover {
  background: #1565C0;
}

.login-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.error-message {
  background: #ff5252;
  color: white;
  padding: 10px;
  border-radius: 4px;
  text-align: center;
}
</style> 