<template>
  <header v-if="isAuthenticated" class="app-header">
    <div class="user-info">
      <span>{{ username }}</span>
      <button @click="handleLogout" class="logout-btn">Çıkış Yap</button>
    </div>
  </header>
  <router-view></router-view>
</template>

<script>
import { useAuth } from '@/composables/useAuth'
import { useRouter } from 'vue-router'

export default {
  name: 'App',
  setup() {
    const { isAuthenticated, username, logout } = useAuth()
    const router = useRouter()

    const handleLogout = () => {
      logout()
      router.push('/login')
    }

    return {
      isAuthenticated,
      username,
      handleLogout
    }
  }
}
</script>

<style>
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #3544AA;
}

.app-header {
  background: #253077;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
}

.user-info {
  display: flex;
  align-items: center;
  gap: 15px;
  color: white;
}

.logout-btn {
  background: #1976D2;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.logout-btn:hover {
  background: #1565C0;
}
</style>
