<template>
    <div class="logo-container">
        <img src="@/assets/all.png" alt="Logo" class="logo">
    </div>
    <div class="content-container">
    <div class="map-container">
        <div id="map"></div>
        <!-- Search Bar -->
        <div class="map-search-container">
          <div class="search-box">
            <input
              type="text"
              v-model="searchQuery"
              @input="handleSearch"
              placeholder="İlçe ara..."
              class="search-input"
            >
            <div v-if="searchResults.length && searchQuery" class="search-results">
              <div
                v-for="result in searchResults"
                :key="result.id"
                @click="selectDistrict(result)"
                class="search-result-item"
              >
                {{ result.name }} - {{ result.province }}
    </div>
        </div>
        </div>
        </div>
    </div>
      <div class="info-panel">
        <div v-if="selectedDistrict" class="panel-content">
          <div class="popup-header">
            {{ selectedDistrict.name }}
            <span v-if="selectedDistrict.party" class="district-party">
              - {{ selectedDistrict.party }}
            </span>
          </div>
          <div class="popup-subheader">{{ selectedDistrict.province }}</div>
          <div class="status-container">
            <div class="popup-status">Durum: {{ getStatusText(selectedDistrict.status) }}</div>
            <div class="action-buttons">
              <button @click="showStatusDialog = true" class="action-btn">
                Durum Değiştir
              </button>
              <button @click="showHistory" class="action-btn">
                İşlem Geçmişi
              </button>
          </div>
          </div>

          <div class="notes-section">
            <div class="section-title">Notlar</div>
            
            <!-- Not Ekleme Formu -->
            <div class="note-form">
              <textarea 
                v-model="newNote" 
                class="note-input"
                placeholder="Yeni not ekle..."
              ></textarea>
              <button 
                @click="addNote" 
                class="add-note-btn"
                :disabled="!newNote.trim()"
              >
                Not Ekle
              </button>
      </div>

            <!-- Notlar Listesi -->
            <div class="notes-list">
              <div v-if="!selectedDistrict.notes?.length" class="no-notes">
                Henüz not eklenmemiş
              </div>
              <div 
                v-for="note in selectedDistrict.notes" 
                :key="note.id" 
                class="note-item"
              >
                <div class="note-content">{{ note.content }}</div>
                <div class="note-meta">
                  <span class="note-user">{{ note.user }}</span>
                  <span class="note-date">{{ formatDate(note.created_at) }}</span>
                  <button 
                    @click="deleteNote(note.id)" 
                    class="delete-note-btn"
                    title="Notu Sil"
                  >
                    🗑️
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="files-section">
            <div class="section-title">Dosyalar</div>

            <!-- Dosya Yükleme -->
            <div class="file-upload">
              <input type="file" ref="fileInput" @change="handleFileUpload" class="file-input" multiple />
              <button @click="$refs.fileInput.click()" class="upload-btn">
                Dosya Seç
              </button>
              <span class="selected-files" v-if="selectedFiles.length">
                {{ selectedFiles.length }} dosya seçildi
              </span>
            </div>

            <!-- Yükleme Butonu -->
            <button @click="uploadFiles" class="upload-submit-btn" :disabled="!selectedFiles.length">
              Dosyaları Yükle
            </button>

            <!-- Dosya Listesi -->
            <div v-if="selectedDistrict?.files && selectedDistrict.files.length > 0" class="files-list">
              <div v-for="file in selectedDistrict.files" :key="file.name" class="file-item">
                <div class="file-info">
                  <span class="file-name">{{ file.name }}</span>
                  <span class="file-date">{{ formatDate(file.created_at) }}</span>
                </div>
                <div class="file-actions">
                  <button @click="downloadFile(file.name)" class="file-action-btn download" title="İndir">
                    ⬇️
                  </button>
                  <button @click="deleteFile(file.name)" class="file-action-btn delete" title="Sil">
                    🗑️
                  </button>
                </div>
              </div>
            </div>
            <div v-else class="no-files">
              Henüz dosya yüklenmemiş
            </div>
          </div>

        </div>
        <div v-else class="no-selection">
          <div class="no-selection-text">Belediye Seçilmedi</div>
          <div class="no-selection-subtext">Haritadan bir belediye seçin</div>
        </div>
      </div>
    </div>
    
  <!-- Loading göstergesi -->
  <div v-if="loading" class="loading-overlay">
    <div class="loading-spinner">
      <div class="spinner"></div>
      <div class="loading-text">{{ loadingText }}</div>
    </div>
  </div>
  <!-- Error göstergesi -->
  <div v-if="error" class="error-message">
    {{ error }}
  </div>
  <!-- History Dialog -->
  <div v-if="showHistoryDialog" class="dialog-overlay" @click="closeHistory">
    <div class="history-dialog" @click.stop>
      <div class="dialog-header">
        <h2>{{ selectedDistrict?.province }} / {{ selectedDistrict?.name }} için yapılan değişiklikler</h2>
        <button class="close-dialog-btn" @click="closeHistory">&times;</button>
      </div>

      <h4 style="margin-left: 20px;">İşlem Türüne Göre Filtrele</h4>

      <div class="history-filter">
        <select v-model="selectedActionType" class="action-type-select">
          <option v-for="type in actionTypes" 
                  :key="type.value" 
                  :value="type.value">
            {{ type.label }}
          </option>
        </select>
      </div>

      <div class="history-list">
        <div v-if="loading" class="history-loading">Yükleniyor...</div>
        <div v-else-if="historyError" class="history-error">{{ historyError }}</div>
        <div v-else-if="!historyItems.length" class="no-history">
          Bu türde işlem geçmişi bulunmuyor
        </div>
        <div v-else class="history-items">
          <div v-for="item in historyItems" :key="item.id" class="history-item">
            <div class="history-item-header">
              <span class="history-type">
                {{ typeof getActionTypeText(item.action_type) === 'function' 
                    ? getActionTypeText(item.action_type)(item) 
                    : getActionTypeText(item.action_type) }}
              </span>
              <span class="history-date">{{ formatDate(item.created_at) }}</span>
            </div>
            <div class="history-user">Kullanıcı: {{ item.username }}</div>
            <div class="history-values">
              <div v-if="item.action_type !== 'note_update' && item.action_type !== 'file_upload'" class="old-value">
                {{ item.action_type === 'file_delete' ? 'Silinen dosya:' : 'Eski:' }}
                {{ item.action_type === 'status_update' ? getStatusText(Number(item.old_value)) : (item.old_value || '-') }}
              </div>
              <div class="new-value">
                {{ 
                  item.action_type === 'note_update' ? 
                  (item.new_value === null ? 'Silinen not:' : 'Not:') :
                  item.action_type === 'file_upload' ? 'Yüklenen dosya:' :
                  item.action_type === 'file_delete' ? '' : 'Yeni:'
                }} 
                <span v-if="item.action_type === 'status_update'">
                  {{ getStatusText(Number(item.new_value)) }}
                </span>
                <span v-else-if="item.action_type === 'note_update' && item.new_value === null" 
                      class="deleted-note">
                  {{ item.old_value }}
                </span>
                <span v-else>
                  {{ item.new_value || '-' }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Status Dialog -->
  <div v-if="showStatusDialog" class="dialog-overlay" @click="showStatusDialog = false">
    <div class="status-dialog" @click.stop>
      <div class="dialog-header">
        <h2>Durum Değiştir</h2>
        <button class="close-dialog-btn" @click="showStatusDialog = false">&times;</button>
      </div>
      <div class="status-options">
        <button 
          v-for="(label, status) in statusOptions" 
          :key="status"
          @click="handleStatusChange(selectedDistrict.id, Number(status))"
          class="status-option-btn"
          :class="{ active: selectedDistrict.status === Number(status) }"
        >
          {{ label }}
        </button>
      </div>
    </div>
  </div>
  <!-- Loading Overlay -->
  <div v-if="modeChangeLoading" class="mode-change-overlay">
    <div class="mode-change-content">
      <div class="mode-change-spinner"></div>
      <div class="progress-bar">
        <div class="progress-fill" :style="{ width: `${progressWidth}%` }"></div>
      </div>
      <div class="mode-change-text">Harita Modu Değiştiriliyor...</div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref, reactive, watch } from 'vue'
import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import axios from 'axios'

// API base URL'ini tanımlayalım
const API_BASE_URL = process.env.VUE_APP_API_URL || 'http://127.0.0.1:8000'

// Leaflet.mask plugin'i
L.Mask = L.Polygon.extend({
  options: {
    stroke: false,
    color: '#333',
    fillOpacity: 1,
    clickable: true,
    outerBounds: L.latLngBounds([-90, -360], [90, 360])
  },

  initialize: function (coords, options) {
    const outerBoundsCoords = [
      this.options.outerBounds.getSouthWest(),
      this.options.outerBounds.getNorthWest(),
      this.options.outerBounds.getNorthEast(),
      this.options.outerBounds.getSouthEast()
    ];
    
    L.Polygon.prototype.initialize.call(this, [outerBoundsCoords], options);
  }
});

L.mask = function (coords, options) {
  return new L.Mask(coords, options);
};

export default {
  name: 'TurkeyMap',
  setup() {
    const mapInstance = ref(null)
    const geoJsonLayer = ref(null)
    const loading = ref(false)
    const error = ref(null)
    const selectedDistrict = ref(null)
    const selectedFiles = ref([])
    const fileInput = ref(null)
    const loadingText = ref('')
    
    // districtStatuses'u API'den alacağız
    const districtStatuses = reactive({})

    // Renklendirme modu için state ekleyelim
    const colorMode = ref('status') // 'status' veya 'party'
    const electionResults = ref({})

    // Yeni state'leri ekleyelim
    const selectedActionType = ref('note_update')
    const actionTypes = [
      { value: 'all', label: 'Tüm İşlemler' },
      { value: 'status_update', label: 'Durum Değişiklikleri' },
      { value: 'bid_update', label: 'Teklif Değişiklikleri' },
      { value: 'note_update', label: 'Not Değişiklikleri' },
      { value: 'file_upload', label: 'Dosya Yüklemeleri' },
      { value: 'file_delete', label: 'Dosya Silmeleri' }
    ]

    // API fonksiyonlarını ekleyelim
    const fetchAllDistricts = async () => {
      try {
        loading.value = true
        const response = await axios.get(`${API_BASE_URL}/districts/`)
        const districts = response.data
        
        // API'den gelen verileri districtStatuses'a dönüştür
        districts.forEach(district => {
          districtStatuses[district.name.toUpperCase()] = district.status
        })
        
        // Haritayı güncelle
        updateMapColors()
      } catch (err) {
        error.value = 'İlçe verileri yüklenirken bir hata oluştu'
        console.error(err)
      } finally {
        loading.value = false
      }
    }

    const getColorByStatus = (status) => {
      switch (status) {
        case 2: return '#3544AA'  // Görüşülüyor - Lacivert
        case 3: return '#4CAF50'  // Aktif - Yeşil
        case 4: return '#ff0000'  // Olumsuz - Kırmızı
        case 5: return '#FFA500'  // Tekrar Görüşülecek - Turuncu
        case 6: return '#40E0D0'  // Hedeflenen - Turkuaz
        default: return '#999999' // Kullanmıyor - Gri
      }
    }

    const getStatusText = (status) => {
      switch (status) {
        case 2: return 'Belediye ile görüşülüyor'
        case 3: return 'Hizmet aktif'
        case 4: return 'Olumsuz'
        case 5: return 'Tekrar Görüşülecek'
        case 6: return 'Hedeflenen'
        default: return 'Hizmeti kullanmıyor'
      }
    }

    // Türkçe karakter normalizasyonu için yardımcı fonksiyon ekleyelim
    const normalizeText = (text) => {
      if (!text) return ''
      
      return text.toString()
        // Önce Türkçe karakterleri değiştir
        .replace(/ğ/gi, 'g')
        .replace(/ü/gi, 'u')
        .replace(/ş/gi, 's')
        .replace(/ı/gi, 'i')
        .replace(/i̇/gi, 'i')
        .replace(/ö/gi, 'o')
        .replace(/ç/gi, 'c')
        .replace(/İ/g, 'I')
        // "MERKEZ" formatını normalize et
        //.replace(/\s+MERKEZ$/i, 'MERKEZ')
        // İl adının tekrarını kaldır (örn: "BARTIN-BARTIN MERKEZ" -> "BARTIN-MERKEZ")
        .replace(/([A-Z]+)-\1\s+/i, '$1-')
        // Tire ve alt çizgileri normalize et
        .replace(/[-]/g, '_')
        .toUpperCase()
        .trim();
    }

    // getDistrictId fonksiyonunu güncelleyelim
    const getDistrictId = (feature) => {
      const province = feature.properties.NAME_1 || feature.properties.province
      const district = feature.properties.NAME_2 || feature.properties.name
      return normalizeText(`${province}_${district}`)
    }

    const createPopupContent = (feature) => {
      const districtName = feature.properties.NAME_2 || feature.properties.name
      const provinceName = feature.properties.NAME_1 || feature.properties.province
      
      return `<div class="district-mini-popup">
        <strong>${districtName}</strong><br>
        ${provinceName}
      </div>`
    }

    // getDistrictParty fonksiyonunu ekleyelim
    const getDistrictParty = (districtId) => {
      if (!districtId) return null
      
      const districtKey = normalizeText(districtId).replace(/_/g, '-')
      const partyInfo = electionResults.value[districtKey] || 
                       Object.entries(electionResults.value || {}).find(([key]) => 
                         normalizeText(key) === normalizeText(districtId))?.[1]
      return partyInfo?.birinci_parti || null
    }

    // handleDistrictClick fonksiyonunu güncelleyelim
    const handleDistrictClick = (feature) => {
      const districtName = feature.properties.NAME_2 || feature.properties.name
      const provinceName = feature.properties.NAME_1 || feature.properties.province
      const districtId = getDistrictId(feature)
      const winningParty = getDistrictParty(districtId)
      
      loadingText.value = 'İlçe bilgileri yükleniyor...'
      loading.value = true

      axios.get(`${API_BASE_URL}/districts/${districtId}`)
        .then(response => {
          const data = response.data || {}
          selectedDistrict.value = {
            id: districtId,
            name: districtName,
            province: provinceName,
            status: data.status || 0,
            bid: data.bid || 0,
            notes: data.notes || [], // Array olarak güncellendi
            files: Array.isArray(data.files) ? data.files : 
                   (data.files ? [data.files] : []),
            party: winningParty
          }
        })
        .catch(err => {
          console.error(err)
          selectedDistrict.value = {
            id: districtId,
            name: districtName,
            province: provinceName,
            status: 0,
            bid: 0,
            notes: [], // Array olarak güncellendi
            files: [],
            party: winningParty
          }
        })
        .finally(() => {
          loading.value = false
        })
    }

    const saveChanges = () => {
      if (!selectedDistrict.value) return
      
      const districtId = selectedDistrict.value.id
      const payload = {
        name: districtId,
        status: selectedDistrict.value.status,
        bid: selectedDistrict.value.bid || null,
        notes: selectedDistrict.value.notes || null
      }
      
      axios.get(`${API_BASE_URL}/districts/${districtId}`)
        .then(response => {
          const method = response.data ? 'put' : 'post'
          const url = method === 'put' 
            ? `${API_BASE_URL}/districts/${districtId}`
            : `${API_BASE_URL}/districts`
          
          return axios[method](url, payload)
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            return axios.post(`${API_BASE_URL}/districts`, payload)
          }
          throw err
        })
        .then(() => {
          districtStatuses[districtId] = selectedDistrict.value.status
          updateMapColors()
          return axios.get(`${API_BASE_URL}/districts/${districtId}`)
        })
        .then(response => {
          const data = response.data || {}
          selectedDistrict.value = {
            ...selectedDistrict.value,
            status: data.status || 0,
            bid: data.bid || 0,
            notes: data.notes || [],
            files: data.files || []
          }
        })
        .catch(err => {
          error.value = 'Değişiklikler kaydedilirken bir hata oluştu'
          console.error(err)
        })
    }

    // updateDistrictStatus fonksiyonunu güncelle
    const updateDistrictStatus = (districtId, newStatus) => {
      if (!selectedDistrict.value) return
      
      // Önce local state'i güncelle
      selectedDistrict.value.status = newStatus
      
      // Mevcut teklif ve notları da içeren payload hazırla
      const payload = {
        name: selectedDistrict.value.id,
        status: newStatus,
        bid: selectedDistrict.value.bid || null,
        notes: selectedDistrict.value.notes || null
      }
      
      loading.value = true
      axios.get(`${API_BASE_URL}/districts/${districtId}`)
        .then(response => {
          const method = response.data ? 'put' : 'post'
          const url = method === 'put' 
            ? `${API_BASE_URL}/districts/${districtId}`
            : `${API_BASE_URL}/districts`
          
          return axios[method](url, payload)
        })
        .catch(err => {
          if (err.response && err.response.status === 404) {
            return axios.post(`${API_BASE_URL}/districts`, payload)
          }
          throw err
        })
        .then(() => {
          // Başarılı kayıt sonrası haritayı güncelle
      districtStatuses[districtId] = newStatus
          updateMapColors()
        })
        .catch(err => {
          error.value = 'Durum güncellenirken bir hata oluştu'
          console.error(err)
          // Hata durumunda eski duruma geri dön
          selectedDistrict.value.status = districtStatuses[districtId] || 0
        })
        .finally(() => {
          loading.value = false
        })
    }

    // getDistrictColor fonksiyonunu güncelleyelim
    const getDistrictColor = (feature) => {
      const districtId = getDistrictId(feature)
      
      if (colorMode.value === 'status') {
        const status = districtStatuses[districtId] || 0
        return getColorByStatus(status)
      } else {
        // İlçe adını seçim sonuçları formatına dönüştür
        const districtKey = normalizeText(districtId)
        const partyInfo = electionResults.value[districtKey.replace(/_/g, '-')] || 
                         Object.entries(electionResults.value || {}).find(([key]) => 
                           normalizeText(key) === districtKey)?.[1]
        return getColorByParty(partyInfo?.birinci_parti || '')
      }
    }

    // Harita güncellemesini güncelle
    const updateMapColors = () => {
      if (geoJsonLayer.value) {
        geoJsonLayer.value.eachLayer((layer) => {
            layer.setStyle({
            fillColor: getDistrictColor(layer.feature),
              fillOpacity: 0.7,
              color: '#444',
            weight: 0.5,
            opacity: 0.8
            })
            layer.bindPopup(createPopupContent(layer.feature))
        })
      }
    }

    const centerMap = () => {
      if (mapInstance.value) {
        mapInstance.value.setView([39.0, 35.0], 6, {
          animate: true,
          duration: 1
        })
      }
    }

    const handleFileUpload = (event) => {
      selectedFiles.value = Array.from(event.target.files)
    }
    
    const uploadFiles = async () => {
      if (!selectedDistrict.value || !selectedFiles.value.length) return
      
      loading.value = true
      const formData = new FormData()
      
      selectedFiles.value.forEach(file => {
        formData.append('file', file)
      })
      
      try {
        await axios.post(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}/upload`,
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
        )
        
        // Dosya listesini güncelle
        await refreshDistrictFiles()
        
        // Formu temizle
        selectedFiles.value = []
        if (fileInput.value) {
          fileInput.value.value = ''
        }
      } catch (err) {
        error.value = 'Dosya yüklenirken bir hata oluştu'
        console.error(err)
      } finally {
        loading.value = false
      }
    }
    
    const downloadFile = async (fileName) => {
      if (!selectedDistrict.value) return
      
      try {
        const response = await axios.get(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}/files/${fileName}`,
          { responseType: 'blob' }
        )
        
        // Dosyayı indir
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', fileName)
        document.body.appendChild(link)
        link.click()
        link.remove()
      } catch (err) {
        error.value = 'Dosya indirilirken bir hata oluştu'
        console.error(err)
      }
    }
    
    const deleteFile = async (fileName) => {
      if (!selectedDistrict.value) return
      
      if (!confirm(`${fileName} dosyasını silmek istediğinizden emin misiniz?`)) {
        return
      }
      
      try {
        await axios.delete(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}/files/${fileName}`
        )
        
        // Dosya listesini güncelle
        await refreshDistrictFiles()
      } catch (err) {
        error.value = 'Dosya silinirken bir hata oluştu'
        console.error(err)
      }
    }
    
    const refreshDistrictFiles = async () => {
      if (!selectedDistrict.value) return
      
      try {
        const response = await axios.get(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}`
        )
        selectedDistrict.value = {
          ...selectedDistrict.value,
          files: response.data.files || []
        }
      } catch (err) {
        console.error('Dosya listesi güncellenirken hata:', err)
      }
    }
    
    const formatFileSize = (bytes) => {
      if (bytes === 0) return '0 B'
      const k = 1024
      const sizes = ['B', 'KB', 'MB', 'GB']
      const i = Math.floor(Math.log(bytes) / Math.log(k))
      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(1))} ${sizes[i]}`
    }

    const showHistoryDialog = ref(false)
    const historyItems = ref([])
    const historyError = ref(null)

    const fetchHistory = async () => {
      if (!selectedDistrict.value?.id) return
      
      historyError.value = null
      loading.value = true
      
      try {
        const params = selectedActionType.value !== 'all' 
          ? { action_type: selectedActionType.value }
          : {}
          
        const response = await axios.get(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}/history`,
          { params }
        )
        historyItems.value = response.data
      } catch (err) {
        historyError.value = 'İşlem geçmişi yüklenirken bir hata oluştu'
        console.error(err)
      } finally {
        loading.value = false
      }
    }

    const closeHistory = () => {
      showHistoryDialog.value = false
      historyItems.value = []
      historyError.value = null
    }

    const getActionTypeText = (type) => {
      switch (type) {
        case 'status_update': return 'Durum Güncelleme'
        case 'bid_update': return 'Teklif Güncelleme'
        case 'note_update': 
          // Yeni not null ise silme işlemi yapılmış demektir
          return item => item.new_value === null ? 'Not Silme' : 'Not Güncelleme'
        case 'file_upload': return 'Dosya Yükleme'
        case 'file_delete': return 'Dosya Silme'
        default: return type
      }
    }

    const formatDate = (dateStr) => {
      const date = new Date(dateStr)
      return new Intl.DateTimeFormat('tr-TR', {
        day: '2-digit',
        month: '2-digit',
        year: 'numeric',
        hour: '2-digit',
        minute: '2-digit'
      }).format(date)
    }

    const showStatusDialog = ref(false)
    const statusOptions = {
      0: 'Kullanmıyor',
      5: 'Tekrar Görüşülecek',
      6: 'Hedeflenen',
      2: 'Görüşülüyor',
      3: 'Aktif',
      4: 'Olumsuz'
    }

    const handleStatusChange = (districtId, status) => {
      updateDistrictStatus(districtId, status)
      showStatusDialog.value = false
    }

    // Seçim sonuçlarını yükle
    const loadElectionResults = async () => {
      try {
        const response = await fetch('/secim_sonuclari.json')
        const data = await response.json()
        electionResults.value = data
      } catch (err) {
        console.error('Seçim sonuçları yüklenirken hata:', err)
      }
    }

    // Parti renklerini tanımla fonksiyonunu güncelleyelim
    const getColorByParty = (party) => {
      if (!party) return '#999999' // Parti bilgisi yoksa gri renk döndür
      
      const normalizedParty = normalizeText(party)
      switch (normalizedParty) {
        case 'AK PARTI':
        case 'AKPARTI':
        case 'AKP': return '#FFA500'
        case 'CHP': return '#CC0000'
        case 'BUYUK BIRLIK': return '#CCBB00'
        case 'MHP': return '#C890A7'
        case 'DEVA':
        case 'DEVA PARTISI': return '#000080'
        case 'DSP': return '#0000e0'
        case 'IYI PARTI':
        case 'IYIPARTI': return '#00BFFF'
        case 'DEM PARTI':
        case 'DEMPARTI': return '#800080'
        case 'YENIDEN REFAH':
        case 'YRP': return '#6E8E59'
        default: return '#999999'
      }
    }

    // Renk modu değiştirme fonksiyonu
    const modeChangeLoading = ref(false)
    const progressWidth = ref(0)
    
    const toggleColorMode = () => {
      modeChangeLoading.value = true
      progressWidth.value = 0
      
      // Progress bar animasyonu
      const duration = 1500 // 2 saniye
      const interval = 20 // Her 20ms'de bir güncelle
      const steps = duration / interval
      const increment = 100 / steps
      
      let currentStep = 0
      const timer = setInterval(() => {
        currentStep++
        progressWidth.value = Math.min(currentStep * increment, 100)
        
        if (currentStep >= steps) {
          clearInterval(timer)
          setTimeout(() => {
            // Mod değişikliğini yap
            colorMode.value = colorMode.value === 'status' ? 'party' : 'status'
            updateMapColors()
            modeChangeLoading.value = false
            progressWidth.value = 0
          }, 100)
        }
      }, interval)
    }

    // Lejandı ve renkleri güncelleyelim
    const createLegend = () => {
      const legend = L.control({ position: 'bottomleft' })

      legend.onAdd = () => {
        const div = L.DomUtil.create('div', 'info legend')
        
        const updateLegendContent = () => {
          let items = []
          
          if (colorMode.value === 'status') {
            items = [
              { color: '#999999', label: 'Kullanmıyor' },
              { color: '#3544AA', label: 'Görüşülüyor' },
              { color: '#4CAF50', label: 'Aktif' },
              { color: '#ff0000', label: 'Olumsuz' },
              { color: '#FFA500', label: 'Tekrar Görüşülecek' },
              { color: '#40E0D0', label: 'Hedeflenen' }
            ]
          } else {
            items = [
              { color: '#FFA500', label: 'AK PARTİ' },
              { color: '#CC0000', label: 'CHP' },
              { color: '#CCBB00', label: 'BÜYÜK BİRLİK' },
              { color: '#C890A7', label: 'MHP' },
              { color: '#000080', label: 'DEVA PARTİSİ' },
              { color: '#0000e0', label: 'DSP' },
              { color: '#00BFFF', label: 'İYİ PARTİ' },
              { color: '#800080', label: 'DEM PARTİ' },
              { color: '#6E8E59', label: 'YENİDEN REFAH' },
              { color: '#999999', label: 'Diğer' }
            ]
          }

          div.innerHTML = `<div class="legend-container">
            ${items.map(item => 
              `<div class="legend-item">
                 <i style="background: ${item.color}"></i>
                 <span>${item.label}</span>
               </div>`
            ).join('')}
          </div>`
        }

        updateLegendContent()
        watch(colorMode, updateLegendContent)

        return div
      }

      return legend
    }

    // selectedActionType değiştiğinde geçmişi yeniden yükle
    watch(selectedActionType, fetchHistory)

    // showHistory fonksiyonunu ekleyelim
    const showHistory = async () => {
      if (!selectedDistrict.value) return
      
      showHistoryDialog.value = true
      await fetchHistory()
    }

    const newNote = ref('')
    
    const addNote = async () => {
      if (!selectedDistrict.value?.id || !newNote.value.trim()) return
      
      try {
        await axios.post(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}/notes`,
          { content: newNote.value.trim() }
        )
        
        // İlçe bilgilerini yeniden yükle
        const response = await axios.get(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}`
        )
        
        selectedDistrict.value = {
          ...selectedDistrict.value,
          notes: response.data.notes || []
        }
        
        // Formu temizle
        newNote.value = ''
      } catch (err) {
        error.value = 'Not eklenirken bir hata oluştu'
        console.error(err)
      }
    }
    
    const deleteNote = async (noteId) => {
      if (!selectedDistrict.value?.id) return
      
      if (!confirm('Bu notu silmek istediğinizden emin misiniz?')) {
        return
      }
      
      try {
        await axios.delete(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}/notes/${noteId}`
        )
        
        // İlçe bilgilerini yeniden yükle
        const response = await axios.get(
          `${API_BASE_URL}/districts/${selectedDistrict.value.id}`
        )
        
        selectedDistrict.value = {
          ...selectedDistrict.value,
          notes: response.data.notes || []
        }
      } catch (err) {
        error.value = 'Not silinirken bir hata oluştu'
        console.error(err)
      }
    }

    const searchQuery = ref('')
    const searchResults = ref([])
    const districtsList = ref([])
    
    // İlçe listesini oluştur
    const createDistrictsList = (geoData) => {
      return geoData.features.map(feature => ({
        id: getDistrictId(feature),
        name: feature.properties.NAME_2 || feature.properties.name,
        province: feature.properties.NAME_1 || feature.properties.province,
        coordinates: feature.geometry.coordinates[0][0],
        feature: feature
      }))
    }
    
    // Arama fonksiyonu
    const handleSearch = () => {
      if (!searchQuery.value.trim()) {
        searchResults.value = []
        return
      }
      
      const query = normalizeText(searchQuery.value)
      searchResults.value = districtsList.value
        .filter(district => 
          normalizeText(district.name).includes(query) ||
          normalizeText(district.province).includes(query)
        )
        .slice(0, 5) // Maksimum 5 sonuç göster
    }
    
    // İlçe seçme fonksiyonu
    const selectDistrict = (district) => {
      if (!mapInstance.value) return
      
      // İlçeye zoom yap
      const bounds = L.geoJSON(district.feature).getBounds()
      mapInstance.value.fitBounds(bounds, {
        padding: [50, 50],
        maxZoom: 10
      })
      
      // Highlight efekti için geçici layer oluştur
      const highlightLayer = L.geoJSON(district.feature, {
        style: {
          fillColor: '#81c784',
          fillOpacity: 0.7,
          color: '#c8e6c9',
          weight: 3
        }
      }).addTo(mapInstance.value)

      // 4 kez yanıp sönme efekti
      let count = 0
      const flash = () => {
        highlightLayer.setStyle({ fillOpacity: count % 2 === 0 ? 0.1 : 0.7 })
        count++
        if (count < 8) { // 4 yanıp sönme için 8 değişim
          setTimeout(flash, 200)
        } else {
          mapInstance.value.removeLayer(highlightLayer)
          // Normal stil ile yeni bir highlight layer ekle
          const permanentHighlight = L.geoJSON(district.feature, {
            style: {
              fillColor: getDistrictColor(district.feature),
              fillOpacity: 0.7,
              color: '#c8e6c9', // Açık yeşil sınır
              weight: 3
            }
          }).addTo(mapInstance.value)
          // 2 saniye sonra kaldır
          setTimeout(() => {
            mapInstance.value.removeLayer(permanentHighlight)
            updateMapColors() // Haritayı normal haline döndür
          }, 2000)
        }
      }
      
      flash()
      
      // İlçeyi seç
      handleDistrictClick(district.feature)
      
      // Arama sonuçlarını temizle
      searchQuery.value = ''
      searchResults.value = []
    }

    onMounted(async () => {
      // Seçim sonuçlarını yükle
      await loadElectionResults()

      const turkeyBounds = L.latLngBounds(
        L.latLng(35.8, 25.6),
        L.latLng(42.3, 44.8)
      )

      const map = L.map('map', {
        attributionControl: false,
        zoomControl: true,
        maxZoom: 18,
        minZoom: 6,
        maxBounds: turkeyBounds.pad(0.1),
        maxBoundsViscosity: 1.0
      }).setView([39.0, 35.0], 6)
      
      mapInstance.value = map

      L.tileLayer('https://{s}.basemaps.cartocdn.com/rastertiles/light_nolabels/{z}/{x}/{y}{r}.png', {
        attribution: '©OpenStreetMap, ©CartoDB',
        subdomains: 'abcd',
        maxZoom: 19,
        bounds: turkeyBounds
      }).addTo(map)

      // Dünya sınırlarını oluştur
      const worldCoords = [
        [[90, -180], [90, 180], [-90, 180], [-90, -180], [90, -180]]
      ];

      // Türkiye sınırlarını oluştur
      let turkeyCoords = [];
      fetch('/turkey-districts.geojson')
        .then(response => response.json())
        .then(data => {
          data.features.forEach(feature => {
            if (feature.geometry.type === 'Polygon') {
              turkeyCoords.push(...feature.geometry.coordinates);
            } else if (feature.geometry.type === 'MultiPolygon') {
              feature.geometry.coordinates.forEach(poly => {
                turkeyCoords.push(...poly);
              });
            }
          });

          // Mask layer'ı oluştur
          L.mask(worldCoords, {
            stroke: false,
            fillColor: '#fff',
            fillOpacity: 1,
            outerBounds: turkeyBounds,
            exclusions: turkeyCoords
          }).addTo(map);

          // İl sınırlarını çiz
          const provinces = {};
          data.features.forEach(feature => {
            const province = feature.properties.NAME_1 || feature.properties.province;
            if (!provinces[province]) {
              provinces[province] = {
                type: 'Feature',
                properties: { name: province },
                geometry: {
                  type: 'MultiPolygon',
                  coordinates: []
                }
              };
            }
            if (feature.geometry.type === 'Polygon') {
              provinces[province].geometry.coordinates.push(feature.geometry.coordinates);
            } else if (feature.geometry.type === 'MultiPolygon') {
              provinces[province].geometry.coordinates.push(...feature.geometry.coordinates);
            }
          });

          L.geoJSON({
            type: 'FeatureCollection',
            features: Object.values(provinces)
          }, {
            style: {
              fillColor: 'none',
              color: '#222',
              weight: 2,
              opacity: 1
            }
          }).addTo(map);

          // İlçe katmanını ekle
          geoJsonLayer.value = L.geoJSON(data, {
            style: (feature) => {
              const districtId = getDistrictId(feature)
              const status = districtStatuses[districtId] || 0
              return {
                fillColor: getColorByStatus(status),
                fillOpacity: 0.7,
                color: '#444',
                weight: 0.5,
                opacity: 0.8
              }
            },
            onEachFeature: (feature, layer) => {
              // Sürükleme durumunu takip et
              let isDragging = false;
              
              map.on('movestart', () => {
                isDragging = true;
                layer.closeTooltip();
              });
              
              map.on('moveend', () => {
                setTimeout(() => {
                  isDragging = false;
                }, 100); // Sürükleme bittikten 100ms sonra hover'ı aktif et
              });
              
              // Tooltip ekle
              const districtName = feature.properties.NAME_2 || feature.properties.name
              const provinceName = feature.properties.NAME_1 || feature.properties.province
              layer.bindTooltip(
                `<div class="tooltip-content">
                  <div class="tooltip-header">${districtName}</div>
                  <div class="tooltip-subheader">${provinceName}</div>
                </div>`,
                {
                  direction: 'top',
                  offset: L.point(0, -5),
                  className: 'district-tooltip',
                  permanent: false,
                  sticky: false
                }
              )
              
              // Mouse over olayını kontrol et
              layer.on('mouseover', (e) => {
                if (!isDragging) {
                  layer.setStyle({
                    fillOpacity: 0.9,
                    weight: 1
                  });
                  layer.openTooltip(e.latlng);
                } else {
                  layer.closeTooltip();
                }
              });
              
              // Mouse out olayını kontrol et
              layer.on('mouseout', () => {
                if (!isDragging) {
                  layer.setStyle({
                    fillOpacity: 0.7,
                    weight: 0.5
                  });
                  layer.closeTooltip();
                }
              });

              layer.on('click', () => handleDistrictClick(feature))
            }
          }).addTo(map);

          // İlçe verilerini API'den al
          fetchAllDistricts().then(() => {
            // Global update fonksiyonunu güncelle
          window.updateStatus = (districtId, status) => {
              updateDistrictStatus(districtId, status)
            }
          })

          // GeoJSON yüklendikten sonra ilçe listesini oluştur
          districtsList.value = createDistrictsList(data)
        });

      // Lejandı ekle
      const legend = createLegend()
      legend.addTo(map)

      // Harita modu değiştirme butonu
      const colorModeButton = L.control({ position: 'bottomleft' })
      colorModeButton.onAdd = () => {
        const container = L.DomUtil.create('div', 'map-buttons-container')
        
        const modeButton = L.DomUtil.create('button', 'map-btn color-mode-btn', container)
        modeButton.innerHTML = 'Harita Modunu Değiştir'
        L.DomEvent.on(modeButton, 'click', toggleColorMode)
        
        const centerButton = L.DomUtil.create('button', 'map-btn center-btn', container)
        centerButton.innerHTML = 'Ortala'
        L.DomEvent.on(centerButton, 'click', centerMap)
        
        return container
      }
      colorModeButton.addTo(map)
    })

    return {
      centerMap,
      loading,
      error,
      selectedDistrict,
      getStatusText,
      updateStatus: updateDistrictStatus,
      saveChanges,
      selectedFiles,
      fileInput,
      handleFileUpload,
      uploadFiles,
      downloadFile,
      deleteFile,
      formatFileSize,
      loadingText,
      showHistoryDialog,
      historyItems,
      historyError,
      showHistory,
      closeHistory,
      getActionTypeText,
      formatDate,
      showStatusDialog,
      statusOptions,
      handleStatusChange,
      colorMode,
      toggleColorMode,
      selectedActionType,
      actionTypes,
      newNote,
      addNote,
      deleteNote,
      searchQuery,
      searchResults,
      handleSearch,
      selectDistrict,
      modeChangeLoading,
      progressWidth
    }
  }
}
</script>

<style>
@import 'leaflet/dist/leaflet.css';

.content-container {
    display: flex;
    gap: 20px;
    margin-bottom: 20px;
}

.map-container {
    height: 600px;
    flex: 1;
    background: white;
  padding: 10px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
  position: relative;
}

#map {
    height: 100%;
  border-radius: 12px;
  overflow: hidden;
}

.container-one {
  display: flex;
  align-items: center;
  gap: 10px;
}

.center-button {
  bottom: 30px;
  margin-top: 50px;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  background-color: white;
  color: #1976D2;
  border: none;
  border-radius: 4px;
  padding: 0 16px;
  height: 36px;
  font-size: 14px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0.4px;
  cursor: pointer;
  box-shadow: 0 3px 1px -2px rgba(0,0,0,.2), 
              0 2px 2px 0 rgba(0,0,0,.14), 
              0 1px 5px 0 rgba(0,0,0,.12);
  transition: background-color 0.3s, box-shadow 0.3s;
}

.center-button:hover {
  background-color: #81b1e8;
  box-shadow: 0 2px 4px -1px rgba(0,0,0,.2), 
              0 4px 5px 0 rgba(0,0,0,.14), 
              0 1px 10px 0 rgba(0,0,0,.12);
}

.center-button:active {
  background-color: #0D47A1;
  box-shadow: 0 5px 5px -3px rgba(0,0,0,.2), 
              0 8px 10px 1px rgba(0,0,0,.14), 
              0 3px 14px 2px rgba(0,0,0,.12);
}

.button-text {
  display: inline-block;
  vertical-align: middle;
  line-height: 36px;
}

/* Popup stilleri */
.district-popup {
  padding: 10px;
  min-width: 200px;
}

.popup-header {
  font-weight: bold;
  font-size: 16px;
  margin-bottom: 8px;
    display: flex;
    align-items: center;
    gap: 8px;
}

.district-party {
  color: #666;
  font-size: 14px;
  font-weight: normal;
}

.popup-subheader {
  font-size: 14px;
  color: #666;
  margin-bottom: 8px;
}

.popup-status {
    color: #121212;
}

.popup-actions {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 10px;
  margin-top: 10px;
}

.status-btn {
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
  white-space: nowrap;
  text-align: center;
}

.status-btn:hover {
  background: #f0f0f0;
}

.status-btn.active {
  background: #1976D2;
  color: white;
  border-color: #1976D2;
}

.status-btn span {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.legend {
    background: white;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  margin: 10px;
  width: fit-content;
  z-index: 1000;
}

.legend-container {
    display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: flex-start;
  max-width: calc(100vw - 40px);
}

.legend-item {
    display: flex;
    align-items: center;
  white-space: nowrap;
  margin-right: 15px;
}

.legend-item i {
  width: 18px;
  height: 18px;
  margin-right: 8px;
  border-radius: 2px;
  flex-shrink: 0;
}

.legend-item span {
  font-size: 12px;
    color: #333;
}

.map-buttons-container {
  display: flex;
  gap: 10px;
}

.map-btn {
  padding: 8px 16px;
  background: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  box-shadow: 0 1px 5px rgba(0,0,0,0.4);
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.map-btn:hover {
  background: #1565C0;
}

.logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.logo {
    height: 60px;
    width: auto;
    object-fit: contain;
}

.loading-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.loading-spinner {
    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0,0,0,0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.spinner {
    width: 30px;
    height: 30px;
    border: 3px solid #f3f3f3;
    border-top: 3px solid #1976D2;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

.loading-text {
    font-size: 14px;
    color: #666;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.error-message {
    background: #ff5252;
    color: white;
    padding: 10px;
    border-radius: 4px;
    margin: 10px 0;
    text-align: center;
}

.info-panel {
    width: 600px;
    height: 450px;
    background: white;
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.3);
    height: 620px; /* map height + padding */
    overflow-y: auto;
}

.panel-content {
  width: 100%;
  height: 450px;
}

.no-selection {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #666;
}

.no-selection-text {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.no-selection-subtext {
    font-size: 16px;
    color: #999;
}

/* Popup stillerini info-panel için güncelle */
.info-panel .popup-header {
    font-size: 24px;
    margin-bottom: 10px;
}

.info-panel .popup-subheader {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
}

.info-panel .popup-status {
    font-size: 16px;
    margin-bottom: 20px;
    color: #666;
}

.info-panel .status-btn {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    background: white;
    border-radius: 4px;
    cursor: pointer;
    transition: all 0.3s;
    white-space: nowrap;
    text-align: center;
}

.info-panel .status-btn:hover {
    background: #f0f0f0;
}

.info-panel .status-btn.active {
    background: #1976D2;
    color: white;
    border-color: #1976D2;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin: 20px 0 10px 0;
}

.bid-input-container {
    position: relative;
    flex: 1;
}

.bid-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    padding-right: 10px;
}

.currency {
    position: absolute;
    right: 50px;
    top: 50%;
    transform: translateY(-50%);
    color: #666;
}

.notes-input {
    width: 90%;
    min-height: 120px;
    padding-right: 50px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 4px;
    resize: vertical;
    margin-bottom: 0px;
}

.save-btn {
    grid-column: 1 / -1;
    width: 100%;
    padding: 12px;
    font-size: 16px;
    background: #4CAF50;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.save-btn:hover {
    background: #45a049;
}

.files-section {
  padding-top: 10px;
  border-top: 1px solid #eee;
}

.file-upload {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
}

.file-input {
  display: none;
}

.upload-btn {
  padding: 8px 16px;
  background: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.upload-submit-btn {
  width: 100%;
  padding: 8px;
  background: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-bottom: 20px;
}

.upload-submit-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.selected-files {
  color: #666;
  font-size: 14px;
}

.files-list {
  margin-top: 10px;
}

.file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 8px;
}

.file-info {
  display: flex;
  align-items: center;
  gap: 12px;
}

.file-name {
  font-size: 14px;
  color: #333;
}

.file-date {
  font-size: 12px;
  color: #666;
}

.file-actions {
  display: flex;
  gap: 8px;
}

.file-action-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.file-action-btn:hover {
  opacity: 1;
}

.no-files {
  text-align: center;
  color: #666;
  padding: 20px;
  border: 2px dashed #eee;
  border-radius: 4px;
}

.history-btn {
  padding: 8px 16px;
  background: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.history-btn:hover {
  background: #1565C0;
}

.dialog-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.history-dialog {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 600px;
  max-height: 80vh;
  display: flex;
  flex-direction: column;
}

.dialog-header {
  padding: 20px;
  border-bottom: 1px solid #eee;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dialog-header h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.close-dialog-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #666;
}

.history-list {
  flex: 1;
  overflow-y: auto;
  padding: 15px;
}

.history-items {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.history-item {
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 4px;
  background: #f9f9f9;
}

.history-item-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.history-type {
  font-weight: bold;
  color: #1976D2;
}

.history-date {
  color: #666;
}

.history-user {
  font-weight: 800;
  font-size: 14px;
  color: #121212;
  margin-bottom: 10px;
}

.history-values {
  display: flex;
  font-weight: 800;
  flex-direction: column;
  gap: 5px;
  font-size: 14px;
}

.old-value {
font-weight: 800;
  color: #d32f2f;
}

.new-value {
  color: #388e3c;
}

.history-loading, .history-error, .no-history {
  text-align: center;
  padding: 20px;
  color: #666;
}

.history-error {
  color: #d32f2f;
}

.bid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.status-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.action-btn {
  padding: 8px 16px;
  background: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.action-btn:hover {
  background: #1565C0;
}

.status-dialog {
  background: white;
  border-radius: 8px;
  width: 90%;
  max-width: 400px;
  display: flex;
  flex-direction: column;
}

.status-options {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.status-option-btn {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #ddd;
  background: white;
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.3s;
}

.status-option-btn:hover {
  background: #f0f0f0;
}

.status-option-btn.active {
  background: #1976D2;
  color: white;
  border-color: #1976D2;
}

.history-filter {
  padding: 15px;
  border-bottom: 1px solid #eee;
  background: #f5f5f5;
}

.action-type-select {
  width: 100%;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  color: white;
  background-color: #1976D2;
  cursor: pointer;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='white'%3e%3cpath d='M7 10l5 5 5-5z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 24px;
  padding-right: 32px;
  transition: background-color 0.3s;
}

.action-type-select:hover {
  background-color: #1565C0;
}

.action-type-select:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(25, 118, 210, 0.2);
}

.action-type-select option {
  background: white;
  color: #333;
  padding: 8px;
}

.notes-section {
  margin: 20px 0;
}

.note-form {
  margin-bottom: 20px;
}

.note-input {
  width: 100%;
  min-height: 80px;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-bottom: 10px;
  font-size: 14px;
  resize: vertical;
}

.add-note-btn {
  padding: 8px 16px;
  background: #1976D2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
}

.add-note-btn:hover {
  background: #1565C0;
}

.add-note-btn:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.notes-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.note-item {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 12px;
}

.note-content {
  margin-bottom: 8px;
  font-size: 14px;
  color: #333;
  white-space: pre-wrap;
}

.note-meta {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 12px;
  color: #666;
}

.note-user {
  font-weight: bold;
}

.delete-note-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  opacity: 0.7;
  transition: opacity 0.3s;
}

.delete-note-btn:hover {
  opacity: 1;
}

.no-notes {
  text-align: center;
  color: #666;
  padding: 20px;
  font-style: italic;
}

.map-search-container {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1000;
  width: 90%;
  max-width: 400px;
}

.search-box {
  position: relative;
  width: 100%;
}

.search-input {
  width: 100%;
  padding: 12px;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  background: #1976D2;
  color: white;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

.search-input:focus {
  outline: none;
  background: #1565C0;
  box-shadow: 0 2px 8px rgba(25, 118, 210, 0.2);
}

.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  margin-top: 4px;
  max-height: 300px;
  overflow-y: auto;
  z-index: 1000;
}

.search-result-item {
  padding: 12px;
  cursor: pointer;
  transition: background-color 0.2s;
  color: #333;
}

.search-result-item:hover {
  background-color: #e3f2fd;
}

.search-result-item + .search-result-item {
  border-top: 1px solid #eee;
}

.mode-change-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

.mode-change-content {
  background: white;
  padding: 30px;
  border-radius: 8px;
  text-align: center;
  min-width: 300px;
}

.mode-change-spinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1976D2;
  border-radius: 50%;
  margin: 0 auto 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.progress-bar {
  width: 100%;
  height: 4px;
  background: #eee;
  border-radius: 2px;
  margin: 20px 0;
  overflow: hidden;
}

.progress-fill {
  height: 100%;
  background: #1976D2;
  transition: width 0.1s linear;
}

.mode-change-text {
  color: #333;
  font-size: 16px;
  margin-top: 10px;
}

.district-tooltip {
  background: white;
  border: none;
  box-shadow: 0 2px 8px rgba(0,0,0,0.2);
  padding: 0;
  border-radius: 4px;
}

.district-tooltip .leaflet-tooltip-content {
  padding: 0;
}

.tooltip-content {
  padding: 8px 12px;
}

.tooltip-header {
  font-weight: bold;
  font-size: 14px;
  color: #333;
}

.tooltip-subheader {
  font-size: 12px;
  color: #666;
  margin-top: 2px;
}

/* Tooltip ok işaretini özelleştir */
.district-tooltip:before {
  border-top-color: white;
}

.deleted-note {
  color: #d32f2f;
}
</style> 