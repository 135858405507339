import { ref } from 'vue'

// Singleton state için değişkenleri composable dışında tanımla
const token = ref(localStorage.getItem('token'))
const isAuthenticated = ref(!!token.value)
const username = ref(localStorage.getItem('username'))

export function useAuth() {
  const login = async (accessToken, userData) => {
    // Token'ı sakla
    token.value = accessToken
    localStorage.setItem('token', accessToken)
    
    // Kullanıcı adını sakla
    username.value = userData?.username
    if (userData?.username) {
      localStorage.setItem('username', userData.username)
    }
    
    isAuthenticated.value = true
  }
  
  const logout = () => {
    token.value = null
    username.value = null
    localStorage.removeItem('token')
    localStorage.removeItem('username')
    isAuthenticated.value = false
  }
  
  // Token'ı alırken "bearer" type ile birlikte gönder
  const getToken = () => token.value ? `bearer ${token.value}` : null
  
  return {
    isAuthenticated,
    username,
    login,
    logout,
    getToken
  }
}